<template>
    <div>
        <b-row class="justify-content-center">
            <b-col cols="auto">
                <div
                    class="card rounded-pill animate__animated animate__backInDown"
                >
                    <div
                        class="d-flex justify-content-center align-items-center p-2"
                    >
                        <div class="text-center mx-2">
                            <p
                                class="text-success font-large-2 font-weight-bold text-shadow"
                            >
                                300
                            </p>
                            <span>Activos</span>
                        </div>
                        <div>
                            <span class="font-large-2">/</span>
                        </div>
                        <div class="text-center mx-2">
                            <p
                                class="text-danger font-large-2 font-weight-bold text-shadow"
                            >
                                300
                            </p>
                            <span>Inactivos</span>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row> </b-row>
        <b-row>
            <b-col>
                <b-card no-body>
                    <b-card-title class="pt-2 pl-2">Profesionales</b-card-title>
                    <tabla-general
                        :columns="tableColumns"
                        :items="profesionales"
                        button-activated
                        :loading="loading"
                        :busy="loading"
                    >
                        <template v-slot:boton>
                            <b-button
                                variant="info"
                                class="p-75"
                                @click="abrirSidebarCrearProfesional"
                                >Crear</b-button
                            >
                        </template>

                        <template v-slot:cell(nombre)="data">
                            <div
                                @click="
                                    $router.push({
                                        name: 'ver-profesional',
                                        params: { id: data.item.id },
                                    })
                                "
                            >
                                {{ data.item.nombre }}
                            </div>
                        </template>
                        <template v-slot:cell(genero)="data">
                            <div>
                                {{ getGenero(data.item.genero) }}
                            </div>
                        </template>
                        <template v-slot:cell(fecha_nacimiento)="data">
                            <div>
                                {{ getEdad(data.item.fecha_nacimiento) }} años
                            </div>
                        </template>
                        <template v-slot:cell(opciones)="data">
                            <div>
                                <b-dropdown variant="link" no-caret>
                                    <template v-slot:button-content>
                                        <feather-icon
                                            icon="MoreVerticalIcon"
                                        ></feather-icon>
                                    </template>
                                    <b-dropdown-item>
                                        <feather-icon
                                            icon="EyeIcon"
                                        ></feather-icon>
                                        Ver</b-dropdown-item
                                    >
                                    <b-dropdown-item>
                                        <feather-icon
                                            icon="EditIcon"
                                        ></feather-icon>
                                        Editar</b-dropdown-item
                                    >
                                    <b-dropdown-item>
                                        <feather-icon
                                            icon="EyeOffIcon"
                                        ></feather-icon>
                                        Inactivar</b-dropdown-item
                                    >
                                    <b-dropdown-item>
                                        <feather-icon
                                            icon="Trash2Icon"
                                        ></feather-icon>
                                        Borrar</b-dropdown-item
                                    >
                                </b-dropdown>
                            </div>
                        </template>
                    </tabla-general>
                </b-card>
            </b-col>
            <!-- sidebars  -->
            <sidebar-crear-profesional
                @update="fetchProfesionales"
                ref="refsidebarCrearProfesional"
            ></sidebar-crear-profesional>
        </b-row>
    </div>
</template>
<script>
import Profesionales from "@/services/profesionales";
import { ref, onMounted } from "@vue/composition-api";
export default {
    emits: ["update"],
    components: {
        SidebarCrearProfesional: () =>
            import("./sidebars/SidebarCrearProfesional.vue"),
    },
    setup(props, context) {
        const loading = ref(false);

        const tableColumns = [
            { key: "identificacion", label: "Identificación" },
            { key: "nombre", sortable: true },
            { key: "apellido", sortable: true },
            {
                key: "fecha_nacimiento",
                label: "Edad",
                sortable: true,
                class: "text-center",
            },
            { key: "genero", sortable: true, class: "text-center" },
            { key: "opciones" },
        ];

        const profesionales = ref([]);

        function abrirSidebarCrearProfesional() {
            context.refs.refsidebarCrearProfesional.toggle();
        }

        async function fetchProfesionales() {
            try {
                loading.value = true;
                const { data } = await Profesionales.listarProfesionales();
                profesionales.value = data.data;
            } catch (error) {
                context.root.catch(error);
            } finally {
                loading.value = false;
            }
        }

        onMounted(() => {
            fetchProfesionales();
        });

        return {
            tableColumns,
            loading,
            profesionales,
            fetchProfesionales,
            abrirSidebarCrearProfesional,
        };
    },
};
</script>
